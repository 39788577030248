import {createRouter, createWebHashHistory} from 'vue-router';
import type {RouteRecordRaw} from 'vue-router';

const NotFound = () => import('@/views/NotFound.vue');
const Error = () => import('@/views/Error.vue');
const Imprint = () => import('@/views/ImprintView.vue');

const Login = () => import('@/views/LoginView.vue');
const Logout = () => import('@/views/LogoutView.vue');
const PasswordReset = () => import('@/views/PasswordReset.vue');
const PasswordResetRequest = () => import('@/views/PasswordResetRequest.vue');
const ActivateUser = () => import('@/views/ActivateUser.vue');

const User = () => import('@/views/JobEmployee/UserView.vue');
const UserRequests = () => import('@/views/JobEmployee/UserRequests.vue');
const UserCommitments = () => import('@/views/JobEmployee/UserCommitments.vue');
const UserTrainings = () => import('@/views/JobEmployee/UserTrainings.vue');
const UserPayrolls = () => import('@/views/JobEmployee/UserPayrolls.vue');

const Admissions = () => import('@/views/AdmissionsView.vue');
const Admission = () => import('@/views/AdmissionView.vue');

const WorkerManagerEvents = () => import('@/views/WorkerManager/EventsOverview.vue');
const WorkerManagerEvent = () => import('@/views/WorkerManager/EventOverview.vue');

const Events = () => import('@/views/EventsView.vue');
const Event = () => import('@/views/EventView.vue');
const Locations = () => import('@/views/LocationManagement.vue');
const StaffingAgencies = () => import('@/views/JobAdmin/StaffingAgencyManagement.vue');
const TasksManagement = () => import('@/views/JobAdmin/TasksManagement.vue');
const Kiosks = () => import('@/views/KiosksView.vue');
const KioskTenants = () => import('@/views/JobAdmin/KioskTenantManagement.vue');
const EmployeeQualifications = () => import('@/views/EmployeeQualifications.vue');

const Accounting = () => import('@/views/AccountingView.vue');
const WageProfiles = () => import('@/views/WageProfiles.vue');
const Wages = () => import('@/views/WagesView.vue');

const Employees = () => import('@/views/EmployeesView.vue');
const Employee = () => import('@/views/EmployeeView.vue');
const EmployeeApplication = () => import('@/views/EmployeeApplication.vue');
const EmployeeApplicationManage = () => import('@/views/EmployeeApplicationManage.vue');
const EmployeeMissingDocuments = () => import('@/views/EmployeeMissingDocuments.vue');

const CourseManagement = () => import('@/views/JobAdmin/CourseManagement.vue');
const CoursesManagement = () => import('@/views/JobAdmin/CoursesManagement.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login',
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: 'login',
    component: Login,
    path: '/login',
  },
  {
    name: 'imprint',
    component: Imprint,
    path: '/imprint',
  },
  {
    name: 'logout',
    component: Logout,
    path: '/logout',
  },
  {
    name: 'password_reset',
    component: PasswordReset,
    path: '/password_reset/:passwordResetRequestId',
    props: true,
  },
  {
    name: 'password_reset_request',
    component: PasswordResetRequest,
    path: '/password_reset_request',
  },
  {
    name: 'activate_user',
    component: ActivateUser,
    path: '/activate/:newUserEmailId',
    props: true,
  },
  {
    name: 'admissions',
    component: Admissions,
    path: '/admissions',
  },
  {
    name: 'admission',
    component: Admission,
    path: '/admissions/:eventId',
    props: true,
  },
  {
    name: 'worker_manager_events',
    component: WorkerManagerEvents,
    path: '/worker_manager_events',
  },
  {
    name: 'worker_manager_event',
    component: WorkerManagerEvent,
    path: '/worker_manager_events/:eventId/:tab?',
    props: true,
  },
  {
    name: 'locations',
    component: Locations,
    path: '/locations',
  },
  {
    name: 'staffing_agencies',
    component: StaffingAgencies,
    path: '/staffing_agencies',
  },
  {
    name: 'wage_profiles',
    component: WageProfiles,
    path: '/wage_profiles',
  },
  {
    name: 'tasks',
    component: TasksManagement,
    path: '/tasks/:eventId',
    props: true,
  },
  {
    name: 'events',
    component: Events,
    path: '/events',
  },
  {
    name: 'event',
    component: Event,
    path: '/events/:eventId/:tab?',
    props: true,
  },
  {
    name: 'kiosks',
    component: Kiosks,
    path: '/kiosks/:locationId',
    props: true,
  },
  {
    name: 'kiosk_tenants',
    component: KioskTenants,
    path: '/kiosk_tenants',
  },
  {
    name: 'employee_qualifications',
    component: EmployeeQualifications,
    path: '/employee_qualifications',
  },
  {
    name: 'accounting',
    component: Accounting,
    path: '/events/:eventId/accounting',
    props: true,
  },

  {
    name: 'wages',
    component: Wages,
    path: '/wages',
  },
  {
    name: 'employees',
    component: Employees,
    path: '/employees',
  },
  {
    name: 'employee',
    component: Employee,
    path: '/employees/:employeeId',
    props: true,
  },
  {
    name: 'applications',
    component: EmployeeApplicationManage,
    path: '/applications',
  },
  {
    name: 'missing_documents',
    component: EmployeeMissingDocuments,
    path: '/missing_documents',
  },
  {
    name: 'user_profile',
    component: User,
    path: '/profile/:tab?',
  },
  {
    name: 'user_requests',
    component: UserRequests,
    path: '/requests',
  },
  {
    name: 'user_commitments',
    component: UserCommitments,
    path: '/commitments',
  },
  {
    name: 'user_trainings',
    component: UserTrainings,
    path: '/trainings',
  },
  {
    name: 'user_payrolls',
    component: UserPayrolls,
    path: '/payrolls',
  },
  {
    name: 'application',
    component: EmployeeApplication,
    path: '/application',
  },
  {
    name: 'course',
    component: CourseManagement,
    path: '/courses/:courseId/:tab?',
    props: true,
  },
  {
    name: 'courses',
    component: CoursesManagement,
    path: '/courses',
    props: true,
  },
  {
    name: 'not_found',
    path: '/:pathMatch(.*)',
    component: NotFound,
  },
  {
    name: 'error',
    path: '/error',
    component: Error,
  },
];

export default createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});

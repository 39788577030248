<template>
  <div class="ui container" v-if="hasWarnings">
    <div class="ui small yellow icon message" v-if="!hasRegistrationAddress">
      <i class="exclamation circle icon"></i>
      <div class="content">
        <div class="header">Meldeadresse nicht hinterlegt</div>
        <p>
          Bitte vervollständige deine Angaben <i class="right angle icon divider" />
          <RouterLink :to="{name: 'user_profile', params: {tab: 'PersonalData'}}">Meldeadresse</RouterLink>
        </p>
      </div>
    </div>

    <div class="ui small yellow icon message" v-else-if="!hasPayrollData">
      <i class="exclamation circle icon"></i>
      <div class="content">
        <div class="header">Lohnangaben nicht hinterlegt</div>
        <p>
          Bitte vervollständige deine Angaben <i class="right angle icon divider" />
          <RouterLink :to="{name: 'user_profile', params: {tab: 'PayrollData'}}">Lohnangaben</RouterLink>
        </p>
      </div>
    </div>

    <div class="ui small yellow icon message" v-else-if="!hasEmergencyContact">
      <i class="exclamation circle icon"></i>
      <div class="content">
        <div class="header">Notfallkontakt nicht hinterlegt</div>
        <p>
          Bitte vervollständige deine Angaben <i class="right angle icon divider" />
          <RouterLink :to="{name: 'user_profile', params: {tab: 'ContactData'}}">Notfallkontakt</RouterLink>
        </p>
      </div>
    </div>

    <br />
  </div>
</template>

<script setup lang="ts">
import {useUserStore} from '@/stores/user';
import {computed} from 'vue';

const userStore = useUserStore();

const hasRegistrationAddress = computed(() => !!userStore.personnelData.properties.registration_address);
const hasPayrollData = computed(() => !!userStore.personnelData.properties.payroll_data);
const hasEmergencyContact = computed(() => !!userStore.personnelData.properties.emergency_contact);

const hasAllValues = computed(() => hasRegistrationAddress.value && hasPayrollData.value && hasEmergencyContact.value);

const hasWarnings = computed(() => userStore.personnelData.employee_id && !hasAllValues.value);
</script>
